import { Flex, Text } from 'rebass';
import { Trans } from '@lingui/macro';

export function BinaryModelDescription({ parsedResult }) {
  return (
    <Flex variant="fluid" alignItems="center" mr={2} ml={1} mt={3} p={3} sx={{ gap: 2, bg: '#f4f8fb' }}>
      {parsedResult?.map((def, i) => {
        const defStyle = { color: def.color, fontWeight: 'bold' };

        let text = undefined;
        switch (def.label) {
          case 'VIR':
            text = (
              <Trans>
                <span style={defStyle}>{def.weight?.toFixed?.(0)} out of 100</span> people with similar blood test
                results are diagnosed with <span style={defStyle}>viral infection</span>.
              </Trans>
            );
            break;

          case 'BAC':
            text = (
              <Trans>
                <span style={defStyle}>{def.weight?.toFixed?.(0)} out of 100</span> people with similar blood test
                results are diagnosed with <span style={defStyle}>bacterial infection</span>.
              </Trans>
            );
            break;

          default:
            text = (
              <Trans>
                <span style={defStyle}>{def.weight?.toFixed?.(0)} out of 100</span> people with similar blood test
                results are diagnosed with `<span style={defStyle}>{def.labelDescription}</span>`.
              </Trans>
            );
            break;
        }

        return (
          <Text key={i} sx={{ textAlign: 'left' }} fontSize={1}>
            {text}
          </Text>
        );
      })}
    </Flex>
  );
}
