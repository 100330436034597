import Loader from 'components/common/Loader';
import useGlobal from 'hooks/global';
import { getAllParameters, getBloodTest, getUser } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import ReportsHeader from 'components/Reports/BloodTestHeader';
import ReportsContainer from 'components/Reports/ReportsContainer';
import BloodTest from 'components/BloodTest';
import { useInterval } from 'hooks/utils';
import { Box, Flex } from 'rebass';
import AvailableModels from 'components/Reports/AvailableModels/AvailableModels';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export default function BloodTestReportContent() {
  const { showError } = useErrorDialog();
  const match = useRouteMatch();
  const { bloodTestId, id: patientId } = useParams();

  const [{ bloodTest, patient }, globalActions] = useGlobal();
  const [isLoading, setIsLoading] = useState(true);

  const updateBloodTest = useCallback(async () => {
    try {
      const bloodTest = await getBloodTest(patientId, bloodTestId);
      await globalActions.setPatientBloodTest(bloodTest);

      const user = await getUser();
      await globalActions.setUser(user);
    } catch (e) {
      showError(e);
      console.log(e);
    }
  }, [globalActions, bloodTestId, patientId, showError]);

  useEffect(() => {
    const fetchBloodTestAndAllParams = async () => {
      try {
        const bloodTest = await getBloodTest(patientId, bloodTestId);
        globalActions.setPatientBloodTest(bloodTest);

        const parameters = await getAllParameters();
        globalActions.setParameters(parameters);

        setIsLoading(false);
      } catch (e) {
        showError(e);
        console.log(e);
      }
    };

    fetchBloodTestAndAllParams();
  }, [globalActions, bloodTestId, patientId, showError]);

  useInterval(() => {
    if (bloodTest.reports) {
      const isProcessingReports = bloodTest.reports.filter((item) => item.status === 'processing');
      if (isProcessingReports.length > 0) {
        updateBloodTest();
      }
    }
  }, 4000);

  if (isLoading) {
    return <Loader big text="Loading blood test" />;
  }

  const hasReports = (bloodTest.reports ?? []).length > 0;
  return (
    <>
      {hasReports ? (
        <>
          <ReportsHeader bloodTest={bloodTest} patient={patient} />

          <Flex variant="bloodTestReport.container">
            <Flex variant="container">
              <Box py={4} sx={{ textAlign: 'center' }}>
                <Box mb={4}>
                  <AvailableModels
                    key="BloodTestReportAvailableModels"
                    onCreateReport={updateBloodTest}
                    match={match}
                    testHasReports
                  />
                </Box>
                <ReportsContainer bloodTest={bloodTest} />
              </Box>
            </Flex>
          </Flex>
        </>
      ) : (
        <BloodTest />
      )}
    </>
  );
}
