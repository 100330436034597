import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

// sentry documentation: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
const history = createBrowserHistory();

if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.05,
  });
}

ReactDOM.render(
  <StrictMode>
    <App history={history} />
  </StrictMode>,
  document.getElementById('root'),
);
