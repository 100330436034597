import { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Text, Image } from 'rebass';
import { Label, Radio, Checkbox } from '@rebass/forms';
import { useForm } from 'react-hook-form';
import VideoPlayer from './VideoPlayer';
import isEqual from 'lodash/isEqual';
import { confirmViewed, getUser } from 'api';
import Logo from 'assets/sba-logo.svg';
import Message from 'components/common/Message';
import useGlobal from 'hooks/global';
import { FaDownload } from 'react-icons/fa';
import ButtonWithLoader from 'components/common/Button';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';
import { withRouter } from 'react-router-dom';

const correctAnswers = {
  q2: 'no',
  q3: 'yes',
  q4: 'yes',
  q5: 'no',
  q6: 'no',
  q7: true,
  q8: true,
  q9: false,
  q10: false,
  q11: '30',
  q12: false,
  q13: true,
  q14: false,
};

const startOfQuizElementId = 'startOfVideoQuiz';

const VideoIntro = ({ user, ...props }) => {
  const { showError } = useErrorDialog();
  const wasScrolled = useRef(false);
  const { register, getValues } = useForm();
  const [isLoading, handleLoading] = useState(false);
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [error, setError] = useState();
  const [showForm, setShowForm] = useState(false);
  const [testCompleted, setTestCompleted] = useState(false);
  const [url, setUrl] = useState();

  const onFinish = async () => {
    handleLoading(true);
    const values = getValues();
    setError(undefined);
    if (isEqual(values, correctAnswers)) {
      try {
        const result = await confirmViewed({ type: 'video' });
        setTestCompleted(true);
        setShowForm(false);
        if (result?.url) {
          setUrl(result.url);
        }
      } catch (e) {
        setError('Ups, something went wrong!');
      }
    } else {
      setError('It looks like not all of the questions were answered correctly. Please try again.');
    }
    handleLoading(false);
  };

  const onContinue = async () => {
    try {
      const user = await getUser();
      globalActions.setUser(user);

      props.history.push('/');
    } catch (e) {
      showError(e);
    }
  };

  const onVideoEnd = () => {
    setShowForm(true);
  };

  useEffect(() => {
    if (showForm && !wasScrolled.current) {
      const element = document.getElementById(startOfQuizElementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        wasScrolled.current = true;
      }
    }
  }, [showForm]);

  return (
    <div>
      <Flex
        variant="container"
        alignItems="center"
        justifyContent="center"
        width="100%"
        bg="lightgray"
        minHeight="100vh"
        maxWidth="100%"
      >
        <Box variant="card" textAlign="left" style={{ width: testCompleted ? '720px' : '980px' }}>
          <Flex mb={4}>
            <Image src={Logo} width={['100%', 'auto']} />
          </Flex>
          {!showForm && !testCompleted && (
            <Text mb={3} width={1} color="text">
              Welcome to the introductory video for the Smart Blood Analytics Swiss Software. <br />
              Before you can use our software, you need to <b>watch the introductory video</b> and successfully&nbsp;
              <b>answer all the questions</b> at the end. <br />
            </Text>
          )}
          {!testCompleted && (
            <VideoPlayer
              onVideoEnd={onVideoEnd}
              url="https://sbas-bucket.fra1.cdn.digitaloceanspaces.com/SBAS_Software/Introductory_video/SBAS_Introductory_video_2023_web.mp4"
            />
          )}

          {showForm && (
            <Flex flexDirection="column" variant="questionare" id={startOfQuizElementId}>
              <Text mt={3} color="text">
                Dear {user.first_name} {user.last_name}, <br />
                <br />
                Thank you for watching the introductory video. To make sure you understand the motivation behind the
                software and how it can help you, please answer the following questions. <br />
                <br />
              </Text>
              <Text color="text" fontWeight="bold" mt={2}>
                What are the SBAS Software indications for use?
              </Text>
              <Flex height="48px" p={3} alignItems="center" justifyContent="space-between">
                <Text color="text">Children</Text>
                <Flex>
                  <Box mr={4}>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q2', { required: true })} value="yes" />
                      Yes
                    </Label>
                  </Box>
                  <Box>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q2', { required: true })} value="no" />
                      No
                    </Label>
                  </Box>
                </Flex>
              </Flex>
              <Flex height="48px" p={3} alignItems="center" justifyContent="space-between" bg="lightgray">
                <Text color="text">18 years and older</Text>
                <Flex>
                  <Box mr={4}>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q3', { required: true })} value="yes" />
                      Yes
                    </Label>
                  </Box>
                  <Box>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q3', { required: true })} value="no" />
                      No
                    </Label>
                  </Box>
                </Flex>
              </Flex>
              <Flex height="48px" p={3} alignItems="center" justifyContent="space-between">
                <Text color="text">
                  Female/Male with acute and/or chronic conditions in the fields of Internal medicine
                </Text>
                <Flex>
                  <Box mr={4}>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q4', { required: true })} value="yes" />
                      Yes
                    </Label>
                  </Box>
                  <Box>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q4', { required: true })} value="no" />
                      No
                    </Label>
                  </Box>
                </Flex>
              </Flex>
              <Flex height="48px" p={3} alignItems="center" justifyContent="space-between" bg="lightgray">
                <Text color="text">Pregnant women</Text>
                <Flex>
                  <Box mr={4}>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q5', { required: true })} value="yes" />
                      Yes
                    </Label>
                  </Box>
                  <Box>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q5', { required: true })} value="no" />
                      No
                    </Label>
                  </Box>
                </Flex>
              </Flex>
              <Flex height="48px" p={3} alignItems="center" justifyContent="space-between">
                <Text color="text">
                  Patients taking drugs that interfere with the results of laboratory blood tests
                </Text>
                <Flex>
                  <Box mr={4}>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q6', { required: true })} value="yes" />
                      Yes
                    </Label>
                  </Box>
                  <Box>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q6', { required: true })} value="no" />
                      No
                    </Label>
                  </Box>
                </Flex>
              </Flex>
              <Text mt={4} color="text" fontWeight="bold">
                Choose which of the following statements apply.
              </Text>
              <Flex variant="checkboxGroup" flexDirection="column">
                <Label>
                  <Checkbox id="remember" {...register('q7', { required: true })} />
                  SBAS Software should not be used as the sole or primary means of diagnosis.
                </Label>
                <Label>
                  <Checkbox id="remember" {...register('q8', { required: true })} />
                  The physician should always consider additional options which are potentially not shown in the SBAS
                  Software report.
                </Label>
                <Label>
                  <Checkbox id="remember" {...register('q9', { required: true })} />
                  The SBAS Software can be used as a screening method on a healthy person.
                </Label>
                <Label>
                  <Checkbox id="remember" {...register('q10', { required: true })} />
                  The SBAS Software can be used on pregnant women and children.
                </Label>
              </Flex>
              <Text mt={4} color="text" fontWeight="bold">
                What’s the minimum number of parameters from the list of blood parameters that need to be provided to
                the SBAS Software?
              </Text>
              <Flex height="48px" alignItems="center" justifyContent="space-between">
                <Flex>
                  <Box mr={4}>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q11', { required: true })} value="10" />
                      10
                    </Label>
                  </Box>
                  <Box mr={4}>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q11', { required: true })} value="30" />
                      30
                    </Label>
                  </Box>
                  <Box>
                    <Label sx={{ alignItems: 'center' }}>
                      <Radio {...register('q11', { required: true })} value="60" />
                      60
                    </Label>
                  </Box>
                </Flex>
              </Flex>
              <Text mt={4} color="text" fontWeight="bold">
                Choose which of the following statements apply.
              </Text>
              <Flex variant="checkboxGroup" flexDirection="column">
                <Label>
                  <Checkbox id="remember" {...register('q12', { required: true })} />
                  When considering the right diagnosis, the emphasis is only on the disease with the lowest Ranking
                  Factor.
                </Label>
                <Label>
                  <Checkbox id="remember" {...register('q13', { required: true })} />
                  When considering the right diagnosis, the emphasis is on the diseases with a high Ranking Factor and
                  Parameter Evidence.
                </Label>
                <Label>
                  <Checkbox id="remember" {...register('q14', { required: true })} /> When considering the right
                  diagnosis, the emphasis is on the diseases with a low Ranking Factor and Parameter Evidence.
                </Label>
              </Flex>

              <Box sx={{ textAlign: 'center', borderTop: '1px solid', borderColor: 'darkgray' }}></Box>
              <Flex mt={3} justifyContent="flex-end">
                <ButtonWithLoader isLoading={isLoading} type="button" onClick={() => onFinish()}>
                  Submit
                </ButtonWithLoader>
              </Flex>
              {error && (
                <Box mt={2}>
                  <Message type="error" message={error} />
                </Box>
              )}
            </Flex>
          )}
          {testCompleted && (
            <Box>
              <Text color="text" my={4} width={1} fontSize={3}>
                <b>Congratulations!</b> You have answered all the questions correctly and you are now ready to start
                using the SBAS Software.
                <br />
                <br />
                You will receive an email with a certificate which confirms that you have successfully completed the
                training.
                {url && (
                  <>
                    <br />
                    <br />
                    To make things easier for you, feel free to click on the link below to download the certificate now.
                    <br />
                    <br />
                    <a href={url} target="_blank" rel="noreferrer" download>
                      <Text variant="clickable">
                        <FaDownload style={{ verticalAlign: 'text-top' }} /> &nbsp; Download certificate
                      </Text>
                    </a>
                  </>
                )}
              </Text>
              <Flex mt={3} justifyContent="flex-end">
                <Button onClick={() => onContinue()}>Continue</Button>
              </Flex>
            </Box>
          )}
        </Box>
      </Flex>
    </div>
  );
};

export default withRouter(VideoIntro);
