import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter, Link } from 'react-router-dom';
import { Flex, Box, Heading, Text, Image, Button } from 'rebass';
import { Input, Label, Checkbox } from '@rebass/forms';

import { login, getUser, login2FA } from 'api';
import { setJwt, setRemember, getRemember } from 'utils/localstorage';
import { renderError } from 'utils';
import useGlobal from 'hooks/global';
import { ButtonWide } from 'components/common/Button';
import TwoFactorInput from 'components/Settings/TwoFactorInput';
import Message from 'components/common/Message';

import Logo from 'assets/sba-logo.svg';
import WaveRight from 'assets/wave-right.png';
import WaveLeft from 'assets/wave-left.png';
import { activateLanguage } from 'components/common/ProtectedRoute';

const Login = (props) => {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [apiError, handleError] = useState('');
  const [isLoading, handleLoading] = useState(false);
  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [tempUser, setTempUser] = useState(); // 2fa stuff
  const [twoFactorApiError, setTwoFactorApiError] = useState('');
  const [twoFactorRenderKey, setTwoFactorRenderKey] = useState('N/A');

  const setUserAndRedirect = async (response) => {
    // set local storage jwt
    setJwt(response);
    // get user
    const user = await getUser();
    // save language
    localStorage.setItem('lang', user.report_language);
    activateLanguage(user.report_language || 'en');
    // set user in global state
    globalActions.setUser(user);
    // push route
    handleLoading(false);

    props.history.push('/');
  };

  const onSubmit = async (data) => {
    handleLoading(true);
    try {
      // reset error state
      handleError('');

      // check if device_hash exists (remember this device)
      const knownDevice = getRemember();
      if (knownDevice) {
        data = {
          ...data,
          device_hash: knownDevice,
        };
      }

      // call login
      const response = await login(data);

      if (response.type === '2fa') {
        setIsTwoFactor(true);
        setTwoFactorApiError('');
        setTempUser(response); // user_id, user_token
      } else {
        // login without 2FA
        setUserAndRedirect(response); // jwt: { access: ..., refresh: ... }
      }
    } catch (e) {
      handleError(renderError(e));
      handleLoading(false);
    }
  };

  const twoFactorLogin = async (code) => {
    const { user_id, user_token } = tempUser;
    const { remember } = getValues();

    try {
      const response = await login2FA(user_id, user_token, code, remember ? 1 : 0);
      if (remember) {
        setRemember(response.device_hash); // localstorage
      }
      setUserAndRedirect(response); // jwt: { access: ..., refresh: ... }
    } catch (e) {
      setTwoFactorRenderKey(Date.now() + Math.random());
      setTwoFactorApiError(e.detail || 'Oops! Something went wrong.');
    }
  };

  return (
    <Flex
      variant="container"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      bg="lightgray"
      minHeight="100vh"
      maxWidth="100%"
    >
      <Box textAlign="center">
        <Image m={[2, 3, 3, 3, 5]} src={Logo} width={300} />
        {!isTwoFactor && (
          <Fragment>
            <Box as="form" onSubmit={handleSubmit(onSubmit)} variant="card">
              <Heading as="h3" color="primary" pb={1} textAlign="center">
                Sign in
              </Heading>
              <Label mt={3}>Email</Label>
              <Input className={errors.password ? 'error' : ''} id="email" {...register('email', { required: true })} />
              {errors.email && (
                <Text py={2} fontSize={1} color="orange">
                  Email is required
                </Text>
              )}
              <Label mt={3}>Password</Label>
              <Input
                className={errors.password ? 'error' : ''}
                id="password"
                {...register('password', { required: true })}
                type="password"
              />
              {errors.password && (
                <Text py={2} fontSize={1} color="orange">
                  Password is required
                </Text>
              )}
              <Flex justifyContent="flex-end">
                <ButtonWide
                  type="submit"
                  mt={4}
                  mb={4}
                  variant="primary"
                  isLoading={isLoading}
                  width="100%"
                  sx={{ textAlign: 'center' }}
                >
                  Login
                </ButtonWide>
              </Flex>
              {apiError && <Message mb={4} type="error" message={apiError} handleHide={() => handleError('')} />}
              <Box
                width={1}
                alignSelf="center"
                color="text"
                sx={{ textAlign: 'center', borderTop: '1px solid', borderColor: 'darkgray' }}
              >
                <Text p={2} fontSize={16}>
                  <br />
                  <Link to="/reset-password">Forgot password?</Link>
                </Text>
              </Box>
            </Box>
            <Heading pt={5} color="primary">
              Don’t have a Health Professional account yet?
            </Heading>
            <Text pb={4} pt={2} fontSize={2} textAlign="center">
              <Link to="/signup">Sign up</Link>
            </Text>
          </Fragment>
        )}
        {isTwoFactor && (
          <Box onSubmit={handleSubmit(onSubmit)} variant="card">
            <Heading as="h3" color="primary" pb={1} textAlign="center">
              Two-factor authentication
            </Heading>
            <Flex my={4} alignItems="center" flexDirection="column">
              <Text color="text">Authentication code (6 digits)</Text>
              <TwoFactorInput login twoFactorLogin={twoFactorLogin} key={twoFactorRenderKey} />
              <Label
                width="auto"
                py={3}
                className={errors.marketing_emails ? 'error' : ''}
                sx={{ alignItems: 'center' }}
              >
                <Checkbox id="remember" {...register('remember')} />
                Remember this device for 2 days
              </Label>
            </Flex>
            {twoFactorApiError && <Message mb={4} type="error" message={twoFactorApiError} />}
            <Text my={4} fontSize={2} color="text">
              Open the two-factor authentication app on your device to view your authentication code and verify your
              identity.
            </Text>
            <Flex justifyContent="center">
              <Button
                mt={1}
                mb={4}
                variant="small"
                width="100%"
                sx={{ textAlign: 'center' }}
                onClick={() => setIsTwoFactor(false)}
              >
                Back to login
              </Button>
            </Flex>
            <Box
              width={1}
              alignSelf="center"
              color="text"
              sx={{ textAlign: 'center', borderTop: '1px solid', borderColor: 'darkgray' }}
            >
              <Text p={2} fontSize={16}>
                <br />
                <Link to="mailto:support@sba-swiss.com">Having problems?</Link>
              </Text>
            </Box>
          </Box>
        )}
      </Box>
      <Box width={'100%'}>
        <Flex width={'100%'}>
          <Box width={1 / 2} pl={4}>
            <Image width={['80%', '60%', '60%', '36%', '24%']} src={WaveLeft} />
          </Box>
          <Box width={1 / 2} pr={4} textAlign="right">
            <Image width={['80%', '60%', '60%', '36%', '24%']} src={WaveRight} />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default withRouter(Login);
