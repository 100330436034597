import { useReportIcd } from '..';
import { SubdiagnosesCard } from './SubdiagnosisCard';

export function SubdiagnosisContent({ reportDetails }) {
  const diseases = reportDetails.result?.results;
  const { selectedIcdId, setSelectedIcdId } = useReportIcd();

  let selected = diseases.findIndex((d) => d.code === selectedIcdId);

  if (selected === -1) {
    selected = 0;
  }

  const prev = () => setSelectedIcdId(diseases[selected - 1].code);
  const next = () => setSelectedIcdId(diseases[selected + 1].code);

  const subdiagnoses = Object.entries(reportDetails.subdiagnoses?.[diseases[selected].code] || {})
    .map(([subKey, subText]) => ({
      key: subKey,
      text: subText,
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

  if (!subdiagnoses.length) {
    return null;
  }

  return (
    <SubdiagnosesCard
      subdiagnoses={subdiagnoses}
      selectedIndex={selected}
      disease={diseases[selected]}
      diseaseColor={reportDetails.result?.visualization?.legend?.[selected]?.color}
      diseaseCount={diseases.length}
      next={next}
      prev={prev}
    />
  );
}
