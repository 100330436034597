import { Trans, t } from '@lingui/macro';
import { Box, Flex, Heading } from 'rebass';
import DateFormat from 'components/common/DateFormat';
import useGlobal from 'hooks/global';
import { getSubscriptionBillingEvents } from 'components/common/Dropdown';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import { FaTimes } from 'react-icons/fa';
import Button from 'components/common/Button';
import { useRef, useState } from 'react';
import Message from 'components/common/Message';

export default function Subscriptions() {
  const [
    {
      user: { organisations },
    },
  ] = useGlobal();

  const dialogRef = useRef(null);

  const [successfullySent, setSuccessfullySent] = useState(false);

  return (
    <>
      <CancelSubscriptionDialog ref={dialogRef} onSuccess={() => setSuccessfullySent(true)} />
      <Box p={3} width={1} minHeight={500}>
        <Heading mb={3}>
          <Trans>Subscriptions</Trans>
        </Heading>
        <SubscriptionsTable organizations={organisations || []} />

        <br />

        {successfullySent && (
          <Flex width={1} mb={3}>
            <Message
              type="ok"
              message={t`Our team will update you on the status of your subscription cancellation as soon as possible.`}
            />
          </Flex>
        )}

        <Button
          disabled={successfullySent}
          variant="small"
          sx={{ bg: 'unset', fontSize: 'unset' }}
          mr={3}
          onClick={() => dialogRef.current?.showDialog?.()}
        >
          <FaTimes style={{ height: '0.8em', marginRight: '1ch' }} />
          <Trans>Cancel subscription</Trans>
        </Button>
      </Box>
    </>
  );
}

function SubscriptionsTable({ organizations }) {
  if (organizations.length === 0) {
    return <Trans>You have no subscriptions.</Trans>;
  }

  return (
    <>
      <Flex py={2} color="darkergray" sx={{ borderBottom: '1px solid', borderColor: 'gray' }}>
        <Box width={0.25}>
          <Trans>Organization</Trans>
        </Box>
        <Box width={0.25}>
          <Trans>Plan name</Trans>
        </Box>
        <Box width={0.25}>
          <Trans>Plan status</Trans>
        </Box>
        <Box width={0.25}>
          <Trans>Remaining billing events</Trans>
        </Box>
        <Box width={0.2}>
          <Trans>Plan interval</Trans>
        </Box>
      </Flex>

      {organizations.map((org, subIndex) => (
        <Flex key={subIndex} py={2} sx={{ borderBottom: '1px solid', borderColor: 'gray' }}>
          <Box width={0.25}>{org?.organisation?.name || 'N/A'}</Box>
          <Box width={0.25}>{org?.organisation?.subscription?.plan_name || 'N/A'}</Box>
          <Box width={0.25}>
            <SubscriptionStatus status={org?.organisation?.subscription?.status} />
          </Box>
          <Box width={0.25}>
            {org?.organisation?.subscription.plan_name?.toLowerCase?.() === 'contract'
              ? ''
              : getSubscriptionBillingEvents(org?.organisation?.subscription, 'N/A')}
          </Box>
          <Box width={0.2}>
            <DateFormat value={org?.organisation?.subscription?.date_start} />
            &nbsp; - &nbsp;
            <DateFormat value={org?.organisation?.subscription?.date_end} />
          </Box>
        </Flex>
      ))}
    </>
  );
}

function SubscriptionStatus({ status }) {
  let value = {
    text: 'N/A',
    color: undefined,
    bg: undefined,
  };

  switch (status) {
    case 'active':
      value = {
        text: <Trans>Active</Trans>,
        color: '#059669',
        bg: '#F0FFF4',
      };
      break;

    default:
      value = {
        text: <Trans>Inactive</Trans>,
        color: '#6B7280',
        bg: '#F3F4F6',
      };
      break;
  }

  return (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '0.5rem',
        borderRadius: '0.375rem',
        backgroundColor: value.bg,
        padding: '0.375rem 0.75rem',
        fontSize: '0.75rem',
        fontWeight: '500',
        color: value.color,
      }}
    >
      {value.text}
    </span>
  );
}
