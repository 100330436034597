// report colours
export const colors = [
  '#043969',
  '#0B5BA2',
  '#3F8CD0',
  '#669ED0',
  '#CDF8FB',
  '#FDDB72',
  '#FABE00',
  '#FA9200',
  '#FD7A3F',
  '#FA4E00',
  '#A33300', // other
];
