import { useRef, useState, useCallback, useEffect } from 'react';

import { prepareData } from './utils';
import { Flex } from 'rebass';
import Table from './table';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { BinaryModelDescription } from './modelDescriptions/BinaryModelDescription';
import { useReportIcd } from '..';

const createMarkup = (content) => {
  if (content) {
    const cleanupContent = content.replace('<body>', '').replace('</body>', '');

    return { __html: cleanupContent };
  }
};

const Graph = ({ reportDetails }) => {
  // TODO optimize parsedResult & reportDetails
  // reportDetails duplicated "Desc" reportDetails.desc and not translated in reportDetails.result.results.predictions.desc
  const result = reportDetails.result.results;
  const svgGraphType = reportDetails.result?.svg_type;
  const parsedResult = prepareData(
    result,
    svgGraphType,
    reportDetails.result?.visualization_settings,
    reportDetails.desc,
  );
  const refContainer = useRef('');
  const svgGraph = reportDetails.result?.visualization?.svg;
  const [svgInitialized, handleSvgInitialized] = useState(false);
  const { selectedIcdId, setSelectedIcdId } = useReportIcd();
  const [hoveredId, handleHoveredId] = useState();

  /**
   * Graph pointer events
   */
  const pointerHandler = useCallback(
    (event) => {
      const useEventType = typeof window.PointerEvent === 'function' ? 'pointer' : 'mouse';

      event.preventDefault();
      const target = event.originalTarget || event.target || event.srcElement.tagName; // webkits originalTarget, safari uses .target, ie .srcElement.tagName
      const id = target.attributes.getNamedItem('cd-id').value;

      if (event.type === 'click') {
        setSelectedIcdId(id);
      }

      if (event.type === `${useEventType}enter` || event.type === 'touchstart') {
        styleOnEnter(target);
        handleHoveredId(id);
      }
      if (event.type === `${useEventType}leave` || event.type === 'touchend') {
        styleOnLeave(target);
        handleHoveredId(null);
      }
    },
    [setSelectedIcdId],
  );

  /**
   * Style pie chart piece on mouse enter
   */
  const styleOnEnter = (target) => {
    target.style.zIndex = '9999';
    target.style.filter = 'drop-shadow(0px 3px 2px rgba(0, 0, 0, .7))';
    target.style.cursor = 'pointer';
    target.setAttribute('stroke', '#333');
    target.setAttribute('stroke-width', '2px');
    // event.originalTarget.setAttribute(
    //   'transform',
    //   'translate(' + displace * Math.cos(ang) + ',' + displace * Math.sin(ang) + ')',
    // );
  };

  /**
   * Style pie chart piece on mouse leave
   */
  const styleOnLeave = (target) => {
    target.style.filter = 'none';
    target.setAttribute('stroke', 'none');
    target.setAttribute('stroke-width', '0');
    target.style.zIndex = 'initial';
  };

  const handleMouseEnter = (id) => {
    handleHoveredId(id);
    const target = getTarget(id);
    styleOnEnter(target);
  };

  const handleMouseLeave = (id) => {
    handleHoveredId(null);
    const target = getTarget(id);
    styleOnLeave(target);
  };

  /**
   * Find the correct solidArc to style
   */
  const getTarget = (id) => {
    const graph = get(refContainer, 'current.children[0].children[0].children[0]');
    const pieParents = filter(graph.children, (item) => item.nodeName === 'g');
    const solidArcToMark = pieParents
      .map((item) => item.children[0])
      .filter((item) => item.attributes.getNamedItem('cd-id').value === id);

    return solidArcToMark[0];
  };

  useEffect(() => {
    const graph = get(refContainer, 'current.children[0].children[0].children[0]');
    if (graph) {
      const pieParents = filter(graph.children, (item) => item.nodeName === 'g');
      const solidArcs = pieParents.map((item) => item.children[0]);

      const useEventType = typeof window.PointerEvent === 'function' ? 'pointer' : 'mouse';
      const listeners = [
        'click',
        'touchstart',
        'touchend',
        // 'touchmove',
        `${useEventType}enter`,
        `${useEventType}leave`,
        // `${useEventType}move`,
      ];

      if (!svgInitialized && solidArcs && svgGraphType !== 'binary') {
        handleSvgInitialized(true);
        solidArcs.forEach((solidArc) =>
          listeners.forEach((etype) => {
            solidArc.addEventListener(etype, pointerHandler);
          }),
        );
      }
    }
  }, [pointerHandler, svgInitialized, svgGraphType]);

  if (['slider', 'covid'].includes(svgGraphType)) {
    return (
      <Flex p={2} width={[1, 1, 1, 1, 1]} alignItems="center" justifyContent="center">
        <div dangerouslySetInnerHTML={createMarkup(svgGraph)} />
      </Flex>
    );
  }

  return (
    <Flex variant="fluid" alignItems="center" py={3}>
      <Flex width={[1, 1, 1, 1, 5 / 10]} sx={{ order: [2, 2, 2, 2, 1] }} flexDirection={'column'}>
        <Table
          svgGraphType={svgGraphType}
          result={parsedResult}
          reportDetails={reportDetails}
          selectedId={selectedIcdId}
          hoveredId={hoveredId}
          handleClickedId={setSelectedIcdId}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />

        {svgGraphType === 'binary' && <BinaryModelDescription parsedResult={parsedResult} />}
      </Flex>
      <Flex width={[1, 1, 1, 1, 5 / 10]} sx={{ order: [1, 1, 1, 1, 2] }} justifyContent="center" alignSelf="flex-start">
        <div ref={refContainer} className="sba-graph" style={{ width: 520, height: 500 }}>
          <div
            ref={refContainer}
            className="sba-graph"
            style={{ height: 500 }}
            dangerouslySetInnerHTML={createMarkup(svgGraph)}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default Graph;
