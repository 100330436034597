import { colors } from 'constants/colors';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';

export const prepareData = (result, svgGraphType, visualizationSettings, desc) => {
  if (svgGraphType === 'binary') {
    return getBinaryResult(result, visualizationSettings, desc);
  }

  return getGenericResult(result, visualizationSettings);
};

const getBinaryResult = (result, visualizationSettings, desc) => {
  let morphedResult = [];

  if (result && result.prediction) {
    const { prediction } = result;

    morphedResult = (visualizationSettings || []).map((setting, index) => {
      const label = setting.icd_code;
      const weight = round(prediction.P[label] * 100, 2);

      return {
        color: setting.color,
        label,
        labelDescription: desc?.[setting.icd_code] || label,
        weight,
        order: setting.order,
        id: index,
      };
    });
  }

  return morphedResult;
};

const getGenericResult = (result) => {
  let weight_sum = 0;
  let morphedResult = [];

  if (result && result.prediction) {
    let { prediction } = result;

    let sortedPredictions = {};
    const pairs = toPairs(prediction.P);
    sortedPredictions = sortBy(pairs, (o) => o[1]).reverse();
    sortedPredictions = fromPairs(sortedPredictions);

    let keys = Object.keys(sortedPredictions);

    morphedResult = keys.map((label, index) => {
      const weight = round(prediction.P[label] * 100, 2);
      const score = round(prediction.I[label], 2);
      weight_sum += weight;

      return {
        color: colors[index],
        label,
        score,
        weight,
        order: index,
        id: index,
      };
    });
  }

  // other
  morphedResult.push({
    color: colors[10],
    id: 10,
    label: 'Other',
    order: 0,
    score: 0,
    weight: 100 - weight_sum,
  });

  return morphedResult;
};
