import { Box, Button } from 'rebass';
import { FaChevronDown } from 'react-icons/fa';
import Loader from 'components/common/Loader';
import { Menu, MenuItem } from '@szhsin/react-menu';
import ModelButton from './ModelButton';

export default function ModelGroup({ group, activeIndex, disabled, onModelClick }) {
  const groupName = group.product_name;
  const models = group.models || [];
  const isActiveGroupName = _beforeLast(activeIndex, '_') === groupName;
  const isOnlyOneModelInGroup = models.length === 1;
  const isFree = !group.requires_billing_event;
  const hideWrapper = isOnlyOneModelInGroup;
  const isTightVersion = !isOnlyOneModelInGroup;

  return (
    <div style={{ position: 'relative' }}>
      <WrapContentAsMenu
        bg={isFree ? 'orange' : 'primary'}
        groupName={groupName}
        hideWrapper={hideWrapper}
        isActiveGroupName={isActiveGroupName}
        activeIndex={activeIndex}
        disabled={disabled}
      >
        {models.map((model, index) => (
          <WrapButtonAsMenuItem key={model.name + index} asMenuItem={!hideWrapper}>
            <ModelButton
              isFree={isFree}
              tightVersion={isTightVersion}
              handleOpen={() => {
                onModelClick(groupName, model.name, index);
              }}
              model={model}
              index={index}
              isLoading={`${groupName}_${index}` === activeIndex}
              disabled={disabled || activeIndex !== null}
            />
          </WrapButtonAsMenuItem>
        ))}
      </WrapContentAsMenu>
    </div>
  );
}

const WrapContentAsMenu = ({ children, hideWrapper, isActiveGroupName, groupName, bg, activeIndex, disabled }) => {
  if (hideWrapper) {
    return <>{children}</>;
  }

  return (
    <Menu
      arrow={true}
      direction="top"
      align="end"
      menuButton={
        <Button
          sx={{ display: 'flex', alignItems: 'center' }}
          disabled={disabled || activeIndex != null}
          variant={'primary'}
          m={2}
          bg={bg}
        >
          {isActiveGroupName && (
            <Box pr={3}>
              <Loader />
            </Box>
          )}
          <Box>
            {groupName} &nbsp; <FaChevronDown style={{ marginBottom: '-2px' }} />
          </Box>
        </Button>
      }
      transition
    >
      {children}
    </Menu>
  );
};

const WrapButtonAsMenuItem = ({ asMenuItem, children }) => {
  if (asMenuItem) {
    return <MenuItem>{children}</MenuItem>;
  }

  return <div>{children}</div>;
};

function _beforeLast(value, delimiter) {
  value = value || '';

  if (delimiter === '') {
    return value;
  }

  const substrings = value.split(delimiter);

  return substrings.length === 1
    ? value // delimiter is not part of the string
    : substrings.slice(0, -1).join(delimiter);
}
